import axios from "axios";
import CryptoJS from "crypto-js";
import { jwtDecode } from "jwt-decode";

const {
  REACT_APP_VANILLA_JWT_URL,
  REACT_APP_VANILLA_SECRET,
  REACT_APP_VANILLA_JWT_AUDIENCE,
  REACT_APP_VANILLA_JWT_ISSUER,
} = process.env;

export interface IAWSResponseGetProfile {
  data: {
    avatar: string;
  };
}

const removeAWSSignature = (avatarLink: string): string => {
  return avatarLink.split("?")[0];
};

const getProfileAvatar = async (
  username: string,
  locale: string | undefined,
): Promise<IAWSResponseGetProfile | null> => {
  try {
    const { data }: IAWSResponseGetProfile = await axios.get(
      `${
        process.env.REACT_APP_BCO_AWS_LAMBDA_URL
      }/api/user/user-avatar?username=${username}&locale=${locale ?? "en"}`,
    );
    return { data };
  } catch {
    return null;
  }
};

const signHS256 = async (payload: Record<string, unknown>) => {
  const header = {
    alg: "HS256",
    typ: "JWT",
  };
  const base64Header = CryptoJS.enc.Base64url.stringify(
    CryptoJS.enc.Utf8.parse(JSON.stringify(header)),
  );
  const base64Payload = CryptoJS.enc.Base64url.stringify(
    CryptoJS.enc.Utf8.parse(JSON.stringify(payload)),
  );

  const signature = CryptoJS.HmacSHA256(
    base64Header + "." + base64Payload,
    CryptoJS.enc.Base64.parse(REACT_APP_VANILLA_SECRET as string),
  );
  const base64Sign = CryptoJS.enc.Base64url.stringify(signature);
  const token = base64Header + "." + base64Payload + "." + base64Sign;

  return token;
};

const parseJwt = (token: string): Record<string, unknown> => {
  return jwtDecode(token);
};

export const isTokenExpired = (authToken: string) => {
  const token = parseJwt(authToken) as Record<string, unknown>;
  if (token) return (token["exp"] as number) < Date.now() / 1000;
};

const signTokenWithSecret = async (authToken: string, locale?: string): Promise<string> => {
  const payload = parseJwt(authToken) as Record<string, unknown>;
  const cognitoUsername = payload["sub"] as string;
  const profileAvatar = await getProfileAvatar(cognitoUsername, locale);
  let avatar = "";
  if (profileAvatar?.data !== null) {
    avatar = removeAWSSignature(profileAvatar?.data.avatar || "");
  }
  const newPayload = {
    ...payload,
    avatar,
    aud: REACT_APP_VANILLA_JWT_AUDIENCE,
    iss: REACT_APP_VANILLA_JWT_ISSUER,
  };
  const token = await signHS256(newPayload);

  return token;
};

export const generateVanillaAuthUrl = async (
  idToken: string,
  redirect?: string | null,
  locale?: string,
): Promise<string> => {
  const vanillaToken = await signTokenWithSecret(idToken, locale);

  return `${REACT_APP_VANILLA_JWT_URL}/${locale}/entry/connect/JWTSSO/?authKey=JWTSSODefault&jwt=${vanillaToken}&target=${redirect || "/"}`;
};

export function setCookie(name: string, value: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = d.toUTCString();

  document.cookie = `${name}=${value}; expires=${expires}; SameSite=Lax; Domain=breastcancer.org; path=/`;
}

function removeCookie(name: string): void {
  const cookies = document.cookie.split(";");
  if (name) {
    document.cookie =
      name +
      "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.communitystgvnl.breastcancer.org; HttpOnly=true; Secure; SameSite=None;";
  }

  document.cookie =
    name +
    "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=Lax; Domain=.breastcancer.org; path=/";

  document.cookie =
    name +
    "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=Lax; Domain=.id-staging.breastcancer.org; path=/";

  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(`${name}=`)) {
      const date = new Date();
      date.setTime(date.getTime() - 1);
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
  }
}

export const removeAuthCookies = (name?: string): void => {
  if (name) {
    removeCookie(name);
  }

  removeCookie("authToken");
  removeCookie("idToken");
  removeCookie("refreshToken");
  return;
};

export const setAuthCookies = (authToken: string, refreshToken: string, idToken: string): void => {
  setCookie("authToken", authToken, 1);
  setCookie("idToken", idToken, 1);
  setCookie("refreshToken", refreshToken, 1);
};

export const getCookieByName = (name: string): string | null => {
  const cookies = document.cookie.split(";");

  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return null;
};
