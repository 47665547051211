export const getGoogleAnalyticsDeviceId = (): string => {
  return (
    document?.cookie
      ?.split("; ")
      ?.find((row) => row.startsWith("_ga="))
      ?.split("=GA1.2.")[1] || ""
  );
};

// Get local time as ISO string with offset at the end
export const getLocalTime = (): string => {
  const now = new Date();
  const tzo = -now.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num: number) => {
    const norm = Math.abs(Math.floor(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return (
    now.getFullYear() +
    "-" +
    pad(now.getMonth() + 1) +
    "-" +
    pad(now.getDate()) +
    "T" +
    pad(now.getHours()) +
    ":" +
    pad(now.getMinutes()) +
    ":" +
    pad(now.getSeconds()) +
    "." +
    pad(now.getMilliseconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

// convert date to string YYYY-MM-DD format
export const convertDateFormat = (
  dateUnformatted: string | number | null | undefined,
): string | undefined => {
  if (!dateUnformatted) return undefined;
  const date = new Date(dateUnformatted),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};
