import { DialogTitle, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { type TransitionProps } from "@mui/material/transitions";
import React, { Dispatch, SetStateAction } from "react";
import VideoPlayer from "shared/components/VideoPlayer";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

const VideoModal: React.FC<IProps> = ({ setOpen, open }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"xl"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: 0, p: 0 }}>
          <div style={{ width: "100%", height: "100%" }}>
            <VideoPlayer
              singleVideo={{
                file: "https://cdn.jwplayer.com/videos/8hfcbVLt.mp4",
                image:
                  "https://images.ctfassets.net/zzorm7zihro2/30JqdeN9stDsNC3yFskko8/fa119a075dd7a860fbde5b183cca405b/Join-BCO-Community.jpg",
              }}
            />
          </div>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Dialog>
    </>
  );
};
export default VideoModal;
