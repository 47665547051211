import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { removeAuthCookies } from "helpers/auth";

import { FlexLayout } from "shared/globalStyles";
import Loader from "./components/Loader";

const SignOut: React.FC = () => {
  const [searchParams] = useSearchParams();
  const vanillaCookie = searchParams.get("vfcookie") || "";
  const logoutTo = searchParams.get("logout_to") || process.env.REACT_APP_BCO_CMS_DOMAIN;

  useEffect(() => {
    removeAuthCookies(vanillaCookie);

    const logoutRedirect =
      vanillaCookie !== ""
        ? `${process.env.REACT_APP_VANILLA_JWT_URL?.replace("https://", "")}`
        : logoutTo;

    const redirectionUrlScheme = logoutRedirect?.includes("localhost.breastcancer.org")
      ? "http"
      : "https";

    setTimeout(() => {
      window.location.href = `${redirectionUrlScheme}://${logoutRedirect}`;
    }, 1000);
  }, []);

  return (
    <FlexLayout justify="center" align="center" style={{ height: "100%" }}>
      <Loader loading />
    </FlexLayout>
  );
};

export default SignOut;
