import { LOGIN } from "config/routes";
import { isTokenExpired, generateVanillaAuthUrl, getCookieByName } from "helpers/auth";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const OauthRedirect: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation("global");
  const idToken = getCookieByName("idToken");

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const target = params.get("target");

    if (!idToken || isTokenExpired(idToken)) {
      window.location.href = `${LOGIN}?target=${target}`;
      return;
    }
    if (target?.includes(process.env.REACT_APP_BCO_CMS_DOMAIN as string)) {
      window.location.href = `https://${process.env.REACT_APP_BCO_CMS_DOMAIN}/login/connect?logged=true&locale=${language}`;
      return;
    }
    const redirectPath = target?.split("target=")[1] || "";

    const redirectToVanilla = async (idToken: string, redirectPath: string, language: string) => {
      const vanillaAuthUrl = await generateVanillaAuthUrl(idToken, redirectPath, language);
      window.location.href = vanillaAuthUrl;
    };
    redirectToVanilla(idToken, redirectPath, language);
  }, []);

  return <></>;
};

export default OauthRedirect;
