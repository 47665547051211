import React from "react";
import { Trans } from "react-i18next";

import styled from "@emotion/styled";
import { Theme } from "config/theme";

export const LinkStyles = styled("a")`
  color: ${Theme.colors.primary};
  font-family: "Larsseit";
`;

interface IProps {
  linkText: string;
  text: string;
  href?: string;
}

const CustomLink: React.FC<IProps> = ({ linkText, text, href = "/" }) => {
  return (
    <>
      <Trans i18nKey="userMessagesUnread">
        {text}
        &nbsp;
        <LinkStyles target="_blank" href={href}>
          {linkText}
        </LinkStyles>
      </Trans>
    </>
  );
};

export default CustomLink;
