import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import BcoHeader from "shared/components/BcoHeader";
import { FlexLayout, GridColumnLayout, Text } from "shared/globalStyles";
import { useCheckMobile } from "shared/hooks/useCheckMobile";
import { SignUpConfirmationWrapper } from "./styles";
import { registrationCompleteTracking } from "shared/utils/analytics";

const SignUpConfirmation: React.FC = () => {
  const isMobile = useCheckMobile();
  const { t, i18n } = useTranslation("global");
  const fontSizeTitle = isMobile ? "24px" : "48px";
  const location = useLocation();
  const querystring = window.location.search;
  const params = querystring?.split("?")[1] || "en";

  const [pageData, setPageData] = useState<{
    name?: string;
    username?: string;
    email: string;
    userId: string;
    lastUpdated: string;
    emailOptIn: boolean;
    language: string;
  }>(location.state);

  useEffect(() => {
    setPageData(location.state);
  }, []);

  useEffect(() => {
    if (params) {
      i18n.changeLanguage(params);
    }
  }, [params]);

  useEffect(() => {
    setTimeout(() => {
      registrationCompleteTracking(pageData?.userId, pageData?.lastUpdated, pageData?.emailOptIn);
    }, 2000);
  }, []);

  return (
    <SignUpConfirmationWrapper>
      <BcoHeader />
      <GridColumnLayout padding="50px 0 0 0" columns="1">
        <FlexLayout flexDirection="column">
          <Text fontsize={isMobile ? "14px" : "16px"} fontFamily="Larsseit">
            {t("signUpConfirmation.title", { name: pageData?.name ?? pageData?.username })}
          </Text>
          <br />
          <Text fontsize={fontSizeTitle}>
            {t("signUpConfirmation.description", {
              dynamicValue: pageData?.email ?? "email@email.com",
            })}
          </Text>
        </FlexLayout>
      </GridColumnLayout>
    </SignUpConfirmationWrapper>
  );
};

export default SignUpConfirmation;
