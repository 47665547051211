import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";

interface IStyles {
  height?: string;
  fontWeight?: string;
  margin?: string;
  fontSize?: string;
}

export const ButtonLoader = styled(LoadingButton)`
  height: ${({ height }: IStyles) => (height ? height : "48px")};
  font-weight: ${({ fontWeight }: IStyles) => (fontWeight ? fontWeight : "500")};
  margin: ${({ margin }: IStyles) => margin || "0px"};
  font-size: ${({ fontSize }: IStyles) => (fontSize ? fontSize : "16px")};
  border-radius: 24px;
  padding: 0 25px 0 25px;
`;
