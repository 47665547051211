/* eslint-disable react/react-in-jsx-scope */
import CognitoConfirmSignUp from "pages/Cognito";
import ResetPassword from "pages/Cognito/reset-password";
import ForgotPassword from "pages/ForgotPassword";
import Login from "pages/Login";
import OauthRedirect from "pages/Oauth/redirect";
import SignOut from "pages/SignOut";
import SignUp from "pages/SignUp";
import SignUpConfirmation from "pages/SignUpConfirmation";
import { createBrowserRouter } from "react-router-dom";

export const LOGIN = "/";
export const SIGNUP = "sign-up";
export const SIGNOUT = "sign-out";
export const SIGNUP_CONFIRMATION = "/sign-up-confirmation";
export const FORGOTPASSWORD = "forgot-password";
export const COGNITO_CONFIRM_SIGNUP = "/cognito/confirm-sign-up/:token";
export const COGNITO_RESET_PASSWORD = "/cognito/forgot-password";
export const OAUTH_REDIRECT = "/oauth/redirect";

export const router = createBrowserRouter([
  {
    path: LOGIN,
    element: <Login />,
  },
  {
    path: SIGNUP,
    element: <SignUp />,
  },
  {
    path: FORGOTPASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: SIGNUP_CONFIRMATION,
    element: <SignUpConfirmation />,
  },
  {
    path: COGNITO_CONFIRM_SIGNUP,
    element: <CognitoConfirmSignUp />,
  },
  {
    path: SIGNOUT,
    element: <SignOut />,
  },
  {
    path: COGNITO_RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: OAUTH_REDIRECT,
    element: <OauthRedirect />,
  },
]);
