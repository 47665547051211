import { Box, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { sourceLang } from "helpers/langHelper";
import BcoHeader from "shared/components/BcoHeader";

import { FORGOTPASSWORD, SIGNUP } from "config/routes";
import { generateVanillaAuthUrl, setAuthCookies } from "helpers/auth";
import { IToken, cognitoSignupConfirmation } from "pages/Cognito/services";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextButton from "shared/components/TextButton";
import TextInputControlled from "shared/components/form/TextInputControlled";
import { Error, FlexLayout, GridColumnLayout, Text } from "shared/globalStyles";
import { useCheckMobile } from "shared/hooks/useCheckMobile";
import { IAWSResponse, cognitoSignIn } from "./services";
import { LoginWrapper } from "./styles";

interface formValues {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("token");
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation("global");
  const navigate = useNavigate();
  const isMobile = useCheckMobile();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isConfirmSignUpError, setConfirmSignUpError] = useState<boolean>(false);
  const { handleSubmit, control, formState } = useForm<formValues>();
  const { errors } = formState;

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const target = params.get("target");
  const locale = params.get("locale") ?? language;

  const postLoginRedirect = async (res: IAWSResponse) => {
    const targetVanilla = target?.includes(process.env.REACT_APP_VANILLA_JWT_URL as string);

    if (targetVanilla) {
      const redirectAfter = decodeURIComponent(target?.split("target=")[1] || "");

      const redirectionToVanilla = await generateVanillaAuthUrl(
        res?.data?.IdToken,
        redirectAfter,
        language,
      );

      window.location.replace(redirectionToVanilla);

      return;
    } else {
      const redirectionUrlScheme = target?.includes("localhost.breastcancer.org")
        ? "http"
        : "https";

      const cmsUrl = target ? `${redirectionUrlScheme}://${target}` : process.env.REACT_APP_AWS_CMS;

      let redirectionToCMS = `${cmsUrl}/login/connect?logged=true`;
      if (sourceLang(search)) redirectionToCMS += `&locale=${locale}`;
      if (verificationCode) redirectionToCMS += "&postsignup=true";

      window.location.replace(redirectionToCMS);
    }
  };

  const redirectToLang = (value: string) => {
    location.search = `?locale=${value}&${params.get("target")}`;
    navigate({
      search: location.search,
    });
  };

  useEffect(() => {
    if (locale) {
      changeLanguage(locale);
    }
    if (target && target.includes("/es/")) {
      redirectToLang("es");
    }
  }, [sourceLang, target]);

  const onSubmit = async (value: FieldValues) => {
    try {
      // Get redirect target from url
      // Redirect for the current target application
      setIsError(false);
      setConfirmSignUpError(false);

      setIsLoading(true);

      const { username, password } = value;
      const res = await cognitoSignIn(username, password, language);
      if (res?.data) {
        if (verificationCode) {
          const confirmationCode: IToken = {
            accessToken: res.data.AccessToken,
            confirmationCode: verificationCode,
            locale: locale,
          };
          await cognitoSignupConfirmation(confirmationCode, res.data.AccessToken);
        }
        // bco-ui project only get the user logged in if the auth cookie is set correctly
        setAuthCookies(res.data.AccessToken, res.data.RefreshToken, res.data.IdToken);

        postLoginRedirect(res);
      }

      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.warn(error, "error in login");
      if (error.message.includes("CONFIRM_SIGN_UP_EXCEPTION")) {
        setConfirmSignUpError(true);
      }
      setIsLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    changeLanguage(sourceLang(locale));
    const keyDownHandler = (ev: KeyboardEvent) => {
      if (ev.key === "Enter") {
        ev.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const fontSizeTitle = isMobile ? "36px" : "60px";

  return (
    <>
      <LoginWrapper onSubmit={handleSubmit(onSubmit)}>
        <BcoHeader />
        <GridColumnLayout padding="50px 0 0 0">
          <FlexLayout flexDirection="column">
            <Text fontsize={fontSizeTitle}>
              {t("login.loginTitle")}
              <br />
              <Text fontStyle="italic" fontsize={fontSizeTitle}>
                {t("login.community")}
              </Text>
            </Text>
            <FlexLayout padding="1vh 0 0 0">
              <Text fontFamily="Larsseit">{t("login.notMember")}</Text> &nbsp;
              <Link
                component="button"
                variant="body2"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate({
                    pathname: SIGNUP,
                    search: sourceLang(search),
                  });
                }}
              >
                <Text fontFamily="Larsseit"> {t("login.joinNow")}</Text>
              </Link>
            </FlexLayout>
            <FlexLayout padding="1vh 0 0 0">
              <Text fontFamily="Larsseit">{t("signUp.helpText")}</Text>
              &nbsp;
              <Link variant="body2" href={`mailto:${t("signUp.helpEmail")}`} target="_blank">
                <Text fontFamily="Larsseit">{t("signUp.helpLinkText")}</Text>
              </Link>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout flexDirection="column" padding={isMobile ? "2vh 0 0 0" : "0px"}>
            {isError && !isConfirmSignUpError && (
              <Error fontsize="16px">{t("error.CognitoSignIn")}</Error>
            )}
            {isError && isConfirmSignUpError && (
              <Error fontsize="16px">{t("error.ConfirmSignUp")}</Error>
            )}
            <br />
            <TextInputControlled
              control={control}
              name={"username"}
              required={true}
              error={errors?.username ? true : false}
              label={t("login.usernameOrEmail")}
            />
            <Box mt={6} />
            <TextInputControlled
              control={control}
              name={"password"}
              type="password"
              required={true}
              error={errors?.password ? true : false}
              label={t("login.password")}
            />
            <FlexLayout padding="2vh 0 0 0">
              <Text fontFamily="Larsseit">{t("login.forgotPassword")}</Text> &nbsp;
              <Link
                component="button"
                variant="body2"
                onClick={(ev) => {
                  ev.preventDefault();
                  navigate({
                    pathname: FORGOTPASSWORD,
                    search: sourceLang(search),
                  });
                }}
              >
                <Text fontFamily="Larsseit"> {t("login.resetPassword")}</Text>
              </Link>
            </FlexLayout>
            <Box mt={8} />
            <TextButton
              fullWidth={false}
              variant="contained"
              type="submit"
              isLoadingButton={isLoading}
              text={t("login.signIn").toString()}
            />
          </FlexLayout>
        </GridColumnLayout>
      </LoginWrapper>
    </>
  );
};

export default Login;
