import Tooltip, { type TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import styled1 from "@emotion/styled";
import { Theme } from "config/theme";
import React from "react";

export const LanguagesTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Theme.colors.secondary,

    border: "1px solid #dadde9",
  },
}));

export const ShowFlags = styled1(IconButton)`
    border-radius: 0;
    margin:0;
    padding:0;

    &:hover {
    background-color: ${Theme.colors.secondary}

  }

`;
export const CurrentLanguage = styled1("span")`
 margin-left:5px;
 font-size:14px;
 color:${Theme.colors.primary};
 font-family: 'Larsseit'

`;
