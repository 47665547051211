import { Checkbox } from "@mui/material";

import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Error, FlexLayout, LarsseitTypography } from "shared/globalStyles";

interface IProps {
  name:
    | "adult"
    | "emailAddress"
    | "reEmailAddress"
    | "getEmail"
    | "lastName"
    | "name"
    | "password"
    | "privacy"
    | "rePassword"
    | "termsAndRules"
    | "username";
  required: boolean;
  error: boolean;
  control: Control<{
    name?: string | undefined;
    getEmail?: boolean | undefined;
    lastName?: string | undefined;
    password: string;
    username: string;
    rePassword: string;
    emailAddress: string;
    reEmailAddress: string;
    adult: boolean;
    termsAndRules: boolean;
    privacy: boolean;
  }>;
  label: string | React.ReactNode;
  isLabelActive?: boolean;
}

const CheckBoxControlled = ({
  control,
  name,
  required = false,
  error,
  label,
  isLabelActive,
}: IProps) => {
  const { t } = useTranslation("global");
  const checkboxLabel = isLabelActive ? label : "";
  return (
    <>
      <FlexLayout flexDirection="column">
        <Controller
          name={name}
          control={control}
          rules={{
            required: required,
          }}
          render={({ field }) => {
            return (
              <FormControlLabel
                control={<Checkbox {...field} checked={!!field["value"]} />}
                label={<LarsseitTypography> {checkboxLabel} </LarsseitTypography>}
              />
            );
          }}
        />
        <Error>{error && <>{t("error.required")}</>}</Error>
      </FlexLayout>
    </>
  );
};

export default CheckBoxControlled;
