import { Box } from "@mui/material";
import { LOGIN } from "config/routes";
import { Theme } from "config/theme";
import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BcoHeader from "shared/components/BcoHeader";
import TextButton from "shared/components/TextButton";
import TextInputControlled from "shared/components/form/TextInputControlled";
import { FlexLayout, LarsseitTypography, Text } from "shared/globalStyles";
import { useCheckMobile } from "shared/hooks/useCheckMobile";
import { cognitoForgotPassword } from "./services";
import { ForgotPasswordContainer } from "./styles";

interface formValues {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const { t, i18n } = useTranslation("global");
  const { handleSubmit, control, formState } = useForm<formValues>();
  const { errors } = formState;
  const isMobile = useCheckMobile();
  const fontSizeTitle = isMobile ? "36px" : "60px";
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const navigate = useNavigate();
  const querystring = window.location.search;
  const params = querystring?.split("target=")[0].split("?")[1] || "en";

  const onSubmit = async (data: FieldValues) => {
    try {
      setIsLoading(true);
      const { email } = data;
      const { language } = i18n;
      await cognitoForgotPassword(email, language);
      setEmailSent(true);
      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setResponseError(error?.response?.data?.message ?? "");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    i18n.changeLanguage(params);
  }, [params]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ForgotPasswordContainer>
        <BcoHeader />
        <FlexLayout flexDirection="column" padding="10vh 0 5vh 0">
          <Text fontsize={fontSizeTitle}>
            {emailSent ? t("forgotPassword.mailSent") : t("forgotPassword.reset")}
            <br />
            {!emailSent && (
              <Text fontStyle="italic" fontsize={fontSizeTitle}>
                {t("forgotPassword.password")}
              </Text>
            )}
          </Text>
          <FlexLayout padding="1vh 0 5vh 0">
            <Text fontFamily="Larsseit">
              {emailSent
                ? t("forgotPassword.mailSentDescription")
                : t("forgotPassword.description")}
            </Text>
            &nbsp;
          </FlexLayout>
          <Box width={isMobile ? "100%" : "50%"}>
            {emailSent ? (
              ""
            ) : (
              <TextInputControlled
                control={control}
                name={"email"}
                required={true}
                error={errors?.email ? true : false}
                label={t("forgotPassword.mail")}
              />
            )}
          </Box>
        </FlexLayout>
        <Box mt={2} />
        {emailSent ? (
          <TextButton
            handleClick={() => navigate(LOGIN)}
            fullWidth={false}
            variant="contained"
            type="button"
            text={t("forgotPassword.backToLogin").toString()}
          />
        ) : (
          <TextButton
            variant="contained"
            type="submit"
            fullWidth={false}
            isLoadingButton={isLoading}
            text={t("forgotPassword.submit").toString()}
          />
        )}
        {responseError && (
          <LarsseitTypography color={Theme.colors.error} fontsize="16px">
            {responseError}
          </LarsseitTypography>
        )}
      </ForgotPasswordContainer>
    </form>
  );
};

export default ForgotPassword;
