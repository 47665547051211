import axios from "axios";

export interface IAWSSignupResponse {
  data: {
    userId: string;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    emailOptIn: boolean;
    consent: boolean;
    locale: string;
    privacyPolicy: boolean;
    legalAge: boolean;
    accessToken: string;
    lastUpdated: string;
  };
}

export interface IUserSignUpInfo {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  emailOptIn: boolean;
  consent: boolean;
  locale: string;
  privacyPolicy: boolean;
  legalAge: boolean;
}

export const cognitoSignup = async (payload: IUserSignUpInfo) => {
  const { data }: IAWSSignupResponse = await axios.post(
    `${process.env.REACT_APP_AWS_COGNITO_URL}/sign-up`,
    payload,
  );

  return { data: data };
};
