import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import global_en from "./en/global.json";
import global_es from "./es/global.json";

i18n.use(LanguageDetector).init({
  whitelist: ["es", "en"],
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  resources: {
    en: { global: global_en },
    es: { global: global_es },
  },

  lng: "en",
});

export default i18n;
