import styled from "@emotion/styled";
import { Link } from "@mui/material";

import { Theme } from "config/theme";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FlexLayout, Text } from "shared/globalStyles";
import VideoModal from "../VideoModal";

export const LinkStyles = styled("span")`
  color: ${Theme.colors.primary};
  font-family: "Larsseit";
  border-bottom: 1px solid ${Theme.colors.primary};
  cursor: pointer;
`;

const AboutCommunityItems: React.FC = () => {
  const { t } = useTranslation("global");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const arrayOfItems = [
    {
      label: t("signUp.item1"),
    },
    {
      label: t("signUp.item2"),
    },
    {
      label: t("signUp.item3"),
    },
    {
      label: t("signUp.item4"),
    },
  ];
  return (
    <>
      <FlexLayout flexDirection="column">
        {arrayOfItems.map((item) => (
          <FlexLayout key={item.label} padding="2vh 0 2vh 0" align="center">
            <Text fontFamily="Larsseit" fontWeight="16px" padding="0 0px 0px 0px">
              {item.label}
            </Text>
          </FlexLayout>
        ))}
        <div style={{ color: Theme.colors.primary, marginTop: "2vh" }}>
          <Trans i18nKey="userMessagesUnread">
            <LinkStyles onClick={handleClickOpen}>{t("signUp.seeMessage")}</LinkStyles>
            &nbsp;
            {t("signUp.message")}
          </Trans>
        </div>
        <FlexLayout padding="4vh 0 0 0">
          <Text fontFamily="Larsseit">{t("signUp.helpText")}</Text>
          &nbsp;
          <Link variant="body2" href={`mailto:${t("signUp.helpEmail")}`} target="_blank">
            <Text fontFamily="Larsseit">{t("signUp.helpLinkText")}</Text>
          </Link>
        </FlexLayout>
      </FlexLayout>
      <VideoModal setOpen={setOpen} open={open} />
    </>
  );
};

export default AboutCommunityItems;
