import { LOGIN } from "config/routes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BcoHeader from "shared/components/BcoHeader";
import TextButton from "shared/components/TextButton";
import { Error, FlexLayout, GridColumnLayout, Text } from "shared/globalStyles";
import { useCheckMobile } from "shared/hooks/useCheckMobile";
import { cognitoSignupConfirmation, IToken } from "./services";
import { CognitoConfirmSignUpWrapper } from "./styles";
import { getCookieByName } from "helpers/auth";

const CognitoConfirmSignUp: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useCheckMobile();
  const isLogged = localStorage.getItem("BCO_LOGGED_IN");
  const authToken = getCookieByName("authToken");
  const { t, i18n } = useTranslation("global");
  const { language } = i18n;
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fontSizeTitle = isMobile ? "24px" : "48px";
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring).get("locale") ?? "en";
  useEffect(() => {
    i18n.changeLanguage(params);
  }, [params]);

  const finishProfile = async () => {
    if (isLogged && token && authToken) {
      try {
        setIsLoading(true);
        const confirmationCode: IToken = {
          accessToken: authToken,
          confirmationCode: token,
          locale: language,
        };
        await cognitoSignupConfirmation(confirmationCode, token);

        setIsLoading(false);
        navigate(LOGIN);
      } catch (error) {
        console.warn(error, "error in confirmationCode");
        setIsLoading(false);
        setIsError(true);
      }
      return;
    }
    navigate({
      pathname: LOGIN,
      search: `?token=${token}&locale=${language}&postsignup=true&target=${
        new URL(process.env.REACT_APP_AWS_CMS as string).hostname
      }`,
    });
    return;
  };
  return (
    <CognitoConfirmSignUpWrapper>
      <BcoHeader />
      <GridColumnLayout padding="50px 0 0 0" columns="1">
        <FlexLayout flexDirection="column">
          <br />
          <Text fontsize={fontSizeTitle}>{t("CognitoConfirmSignUp.description")}</Text>
          <br />
          {isError && <Error fontsize="16px">{t("error.CognitoConfirmSignUp")}</Error>}
          <br />
          <TextButton
            isLoadingButton={isLoading}
            fullWidth={false}
            variant="contained"
            type="submit"
            text={t("CognitoConfirmSignUp.button").toString()}
            handleClick={finishProfile}
          />
        </FlexLayout>
      </GridColumnLayout>
    </CognitoConfirmSignUpWrapper>
  );
};

export default CognitoConfirmSignUp;
