import { useEffect, useState } from "react";

export const useScript = (src: string, head: boolean): string => {
  const [loadingStatus, setStatus] = useState(src ? "loading" : "idle");
  useEffect(() => {
    if (!src) {
      setStatus("idle");
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let script: any = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("data-status", "loading");

      head ? document.head.appendChild(script) : document.body.appendChild(script);

      const setAttributeFromEvent = (event: { type: string }) => {
        script.setAttribute("data-status", event.type === "load" ? "ready" : "error");
      };
      script.addEventListener("load", setAttributeFromEvent);
      script.addEventListener("error", setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute("data-status"));
    }
    const setStateFromEvent = (event: { type: string }) => {
      setStatus(event.type === "load" ? "ready" : "error");
    };

    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
      }
    };
  }, [head, src]);

  return loadingStatus;
};
