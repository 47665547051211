import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider } from "@mui/material";
import { LOGIN, SIGNUP_CONFIRMATION } from "config/routes";
import { setCookie } from "helpers/auth";
import { sourceLang } from "helpers/langHelper";
import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BcoHeader from "shared/components/BcoHeader";
import { FlexLayout, GridColumnLayout, Text } from "shared/globalStyles";
import { useCheckMobile } from "shared/hooks/useCheckMobile";
import { registrationViewTracking } from "shared/utils/analytics";
import AboutCommunityItems from "./components/AboutCommunityItems";

import SignUpForm from "./components/SignUpForm";
import signupSchema from "./schemas/signupSchema";
import { IUserSignUpInfo, cognitoSignup } from "./services";
import { SingUpWrapper } from "./styles";

interface formValues {
  name?: string | undefined;
  lastName?: string | undefined;
  getEmail?: boolean | undefined;
  username: string;
  emailAddress: string;
  reEmailAddress: string;
  password: string;
  rePassword: string;
  adult: NonNullable<boolean | undefined>;
  termsAndRules: NonNullable<boolean>;
  privacy: NonNullable<boolean>;
}

const SignUp: React.FC = () => {
  const { t, i18n } = useTranslation("global");
  const { language } = i18n;
  const navigate = useNavigate();
  const isMobile = useCheckMobile();
  const { handleSubmit, control, formState } = useForm<formValues>({
    resolver: yupResolver(signupSchema),
    mode: "onChange",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string>("");
  const { errors } = formState;
  const fontSizeTitle = isMobile ? "36px" : "60px";

  const search = window.location.search;
  const params = search?.split("target=")[0].split("/")[3] || "en";

  const onSubmit = async (data: FieldValues) => {
    try {
      setIsLoading(true);

      const {
        emailAddress,
        lastName,
        name,
        password,
        termsAndRules,
        username,
        getEmail,
        privacy,
        adult,
      } = data;
      const userInformation = {
        email: emailAddress,
        lastName: lastName,
        firstName: name,
        password: password,
        emailOptIn: getEmail || false,
        consent: !!termsAndRules,
        privacyPolicy: !!privacy,
        legalAge: !!adult,
        username: username,
        locale: language,
      } as IUserSignUpInfo;

      const res = await cognitoSignup(userInformation);

      setCookie("authToken", res.data?.accessToken, 1);

      if (res?.data.email) {
        navigate(`${SIGNUP_CONFIRMATION}?${language}`, {
          state: {
            email: emailAddress,
            name,
            username: res?.data.username,
            userId: res?.data.userId,
            lastUpdated: res?.data.lastUpdated,
            emailOptIn: getEmail || false,
            language,
          },
        });
      }

      setIsLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.warn(error?.response?.data?.message, "error in onSubmit");
      setResponseError(error?.response?.data?.message ?? "");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(sourceLang(search));
  }, [params]);

  useEffect(() => {
    setTimeout(() => {
      registrationViewTracking();
    }, 2000);
  }, []);

  return (
    <SingUpWrapper>
      <BcoHeader
        rightText={"signUp.login"}
        onClick={() =>
          navigate({
            pathname: LOGIN,
            search: sourceLang(search),
          })
        }
      />
      <GridColumnLayout padding="50px 0 0 0" columnGap="10%">
        <FlexLayout flexDirection="column">
          <Text fontsize={fontSizeTitle}>
            {t("signUp.loginTitle")}
            <br />
            <Text fontStyle="italic" fontsize={fontSizeTitle}>
              {t("login.community")}
            </Text>
          </Text>

          <Box width={"100%"} paddingTop="6vh" paddingBottom={"6vh"}>
            <Divider />
          </Box>
          {!isMobile && <AboutCommunityItems />}
        </FlexLayout>
        <SignUpForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          errors={errors}
          control={control}
          isLoading={isLoading}
          responseError={responseError}
        />
      </GridColumnLayout>
    </SingUpWrapper>
  );
};

export default SignUp;
