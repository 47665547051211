import axios from "axios";

export interface IAWSResponse {
  data: {
    AccessToken: string;
    ExpiresIn: number;
    IdToken: string;
    RefreshToken: string;
    TokenType: string;
  };
}
export const cognitoSignIn = async (username: string, password: string, locale: string) => {
  const { data }: IAWSResponse = await axios.post(
    `${process.env.REACT_APP_AWS_COGNITO_URL}/sign-in`,
    {
      username,
      password,
      locale,
    },
  );
  return { data: data };
};
