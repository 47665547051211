import { createTheme } from "@mui/material";

interface IDefaultColors {
  primary: string;
  secondary: string;
  error: string;
  warning: string;
  info: string;
  success: string;
  lightText: string;
  softText: string;
  errorWarning: string;
}

interface ITheme {
  dark: boolean;
  colors: IDefaultColors;
}

// Responsive Values
export const MIN_MOBILE_WIDTH = 0;
export const MAX_MOBILE_WIDTH = 767;
export const MIN_DESKTOP_WIDTH = 1024;
export const MIN_HD_WIDTH = 1280;
export const MIN_LARGE_DESKTOP_WIDTH = 1440;
export const MIN_FHD_WIDTH = 1920;
export const MIN_QHD_WIDTH = 2560;
export const MIN_4K_WIDTH = 3840;
export const MIN_8K_WIDTH = 7680;

export const Theme: ITheme = {
  dark: false,
  colors: {
    primary: "#30293D",
    secondary: "#f5f7fe",
    error: "#B22222",
    warning: "#909090",
    info: "#F2F2F2",
    success: "#DADADA",
    lightText: "#706D77",
    softText: "#30293D",
    errorWarning: "#db160f",
  },
};

export const CustomTheme = createTheme({
  palette: {
    primary: {
      main: Theme.colors.primary,
    },
    secondary: {
      main: Theme.colors.secondary,
    },
  },
  breakpoints: {
    values: {
      xs: MIN_MOBILE_WIDTH,
      sm: MAX_MOBILE_WIDTH,
      md: MIN_DESKTOP_WIDTH,
      lg: MIN_HD_WIDTH,
      xl: MIN_LARGE_DESKTOP_WIDTH,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});
