import React from "react";
import { Control, Controller } from "react-hook-form";

import { TextField } from "@mui/material";
import { Theme } from "config/theme";
import { useTranslation } from "react-i18next";
import { Error, FlexLayout, Text, TextSpan } from "shared/globalStyles";

interface IProps {
  name:
    | "adult"
    | "email"
    | "emailAddress"
    | "reEmailAddress"
    | "getEmail"
    | "lastName"
    | "name"
    | "password"
    | "privacy"
    | "rePassword"
    | "termsAndRules"
    | "username";
  required: boolean;
  error?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  variant?: "standard" | "filled" | "outlined";
  label: string;
  disabled?: boolean;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorMessage?: string | any;
  type?: "password" | "number";
  style?: React.CSSProperties;
  errorToPlaceholder?: string | null;
}

const TextInputControlled: React.FC<IProps> = ({
  control,
  name,
  required = false,
  error,
  variant = "standard",
  label,
  disabled = false,
  placeholder,
  errorMessage,
  type,
  style,
  errorToPlaceholder,
}) => {
  const { t } = useTranslation("global");
  return (
    <>
      <FlexLayout>
        <Controller
          name={name}
          control={control}
          rules={{
            required: required,
          }}
          render={({ field }) => (
            <TextField
              onBlur={field.onBlur}
              type={type}
              fullWidth
              disabled={disabled}
              inputProps={{ style: style }}
              label={
                <Text fontFamily="Larsseit" color={Theme.colors.lightText}>
                  {label}
                </Text>
              }
              onChange={field.onChange}
              value={field.value || ""}
              variant={variant}
              placeholder={placeholder}
              helperText={
                <span>
                  <Error>
                    {error && errorMessage ? (
                      <span>{t(errorMessage)}</span>
                    ) : (
                      error && <span>{t("error.required")}</span>
                    )}
                  </Error>
                  {errorToPlaceholder && (
                    <TextSpan fontFamily="Larsseit" color={Theme.colors.lightText}>
                      {error && errorMessage ? "" : <span>{t(errorToPlaceholder)}</span>}
                    </TextSpan>
                  )}
                </span>
              }
            />
          )}
        />
      </FlexLayout>
    </>
  );
};

export default TextInputControlled;
