import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BcoHeader from "shared/components/BcoHeader";
import { ResetPasswordContainer } from "./styles";
import { FlexLayout, Text, GridColumnLayout, LarsseitTypography } from "shared/globalStyles";
import { useCheckMobile } from "shared/hooks/useCheckMobile";
import { Box } from "@mui/material";
import TextInputControlled from "shared/components/form/TextInputControlled";
import TextButton from "shared/components/TextButton";
import { cognitoPasswordRecovery } from "../services";
import { yupResolver } from "@hookform/resolvers/yup";
import resetPasswordSchema from "./schemas/resetPasswordSchema";
import { LOGIN } from "config/routes";
import { useNavigate } from "react-router-dom";
import { Theme } from "config/theme";

interface formValues {
  password: string;
  rePassword: string;
}

const ResetPassword: React.FC = () => {
  const { handleSubmit, control, formState } = useForm<formValues>({
    resolver: yupResolver(resetPasswordSchema),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<string>("");
  const { t, i18n } = useTranslation("global");
  const isMobile = useCheckMobile();
  const { errors } = formState;
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const username = params.get("username") || "";
  const token = params.get("token") || "";

  const fontSizeTitle = isMobile ? "36px" : "60px";

  const { language } = i18n;
  const locale = params.get("locale") || language;
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [params]);

  const onSubmit = async (data: FieldValues) => {
    try {
      setIsLoading(true);
      const { password } = data;
      const { language } = i18n;
      await cognitoPasswordRecovery(username, token, password, language);
      setIsLoading(false);
      navigate(LOGIN);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setResponseError(error?.response?.data?.message ?? "");
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ResetPasswordContainer>
        <BcoHeader />
        <GridColumnLayout padding="50px 0 0 0">
          <FlexLayout flexDirection="column" padding="10vh 0 5vh 0">
            <Text fontsize={fontSizeTitle}>
              {t("resetPassword.title")}
              <br />
              {
                <Text fontStyle="italic" fontsize={fontSizeTitle}>
                  {t("resetPassword.password")}
                </Text>
              }
            </Text>
            <FlexLayout padding="1vh 0 5vh 0">
              <Text fontFamily="Larsseit">{t("resetPassword.description")}</Text>
              &nbsp;
            </FlexLayout>
          </FlexLayout>
          {/* input fields */}
          <FlexLayout flexDirection="column" padding="10vh 0 5vh 0">
            <TextInputControlled
              control={control}
              type="password"
              name={"password"}
              required={true}
              error={errors?.password ? true : false}
              errorMessage={errors?.password?.message?.toString()}
              label={t("resetPassword.passwordField")}
            />
            <Box mt={2} />
            <TextInputControlled
              control={control}
              name={"rePassword"}
              type="password"
              required={true}
              error={errors?.rePassword ? true : false}
              errorMessage={errors?.rePassword?.message?.toString()}
              label={t("resetPassword.confirmPasswordField")}
              errorToPlaceholder={t("error.password")}
            />
            <Box mt={8} />
            <TextButton
              variant="contained"
              type="submit"
              fullWidth={false}
              isLoadingButton={isLoading}
              text={t("resetPassword.submit").toString()}
            />
          </FlexLayout>
          {responseError && (
            <LarsseitTypography color={Theme.colors.error} fontsize="16px">
              {responseError}
            </LarsseitTypography>
          )}
        </GridColumnLayout>
      </ResetPasswordContainer>
    </form>
  );
};

export default ResetPassword;
