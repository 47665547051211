import React from "react";
import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";

interface LoaderProps {
  loading?: boolean;
}
const Loader = (props: LoaderProps) => {
  const { loading = false } = props;
  return (
    <Card sx={{ p: 10, minWidth: 300, width: "100%" }} elevation={0}>
      <CardHeader
        avatar={loading && <Skeleton animation="wave" variant="circular" width={80} height={80} />}
        title={
          loading && (
            <Skeleton animation="wave" height={50} width="80%" style={{ marginBottom: 6 }} />
          )
        }
        subheader={loading && <Skeleton animation="wave" height={50} width="40%" />}
      />
      {loading && <Skeleton sx={{ height: 250 }} animation="wave" variant="rectangular" />}
      <CardContent>
        {loading && (
          <React.Fragment>
            <Skeleton animation="wave" height={50} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={50} width="80%" />
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
};

export default Loader;
