import React from "react";

//styles
import { ButtonLoader } from "./styles";

interface Props {
  text?: string;
  handleClick?: () => void;
  disabled?: boolean;
  variant?: "text" | "outlined" | "contained";
  fullWidth?: boolean;
  height?: string;
  type?: "button" | "submit" | "reset";
  isLoadingButton?: boolean;
  fontWeight?: string;
  margin?: string;
  fontSize?: string;
  size?: "small" | "medium" | "large";
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
}

const TextButton: React.FC<Props> = ({
  text = "default",
  handleClick,
  disabled = false,
  variant = "contained",
  fullWidth = true,
  height = "48px",
  type = "button",
  isLoadingButton = false,
  fontWeight,
  margin,
  fontSize,
  color = "primary",
  size = "medium",
}: Props) => {
  return (
    <>
      <ButtonLoader
        size={size}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        type={type}
        height={height}
        fullWidth={fullWidth}
        disabled={disabled}
        onClick={handleClick}
        variant={variant}
        margin={margin}
        loading={isLoadingButton}
      >
        {text}
      </ButtonLoader>
    </>
  );
};

export default TextButton;
