import { Button, ButtonBase } from "@mui/material";
import fullLogo from "assets/svg/full-logo.svg";
import logomark from "assets/svg/logomark.svg";

import React from "react";
import { useTranslation } from "react-i18next";
import { FlexLayout, LarsseitTypography } from "shared/globalStyles";
import { useCheckMobile } from "shared/hooks/useCheckMobile";
import ChooseLanguage from "../ChooseLanguages";

interface IProps {
  rightText?: string;
  onClick?: () => void;
}

const BcoHeader: React.FC<IProps> = ({ rightText, onClick }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const isMobile = useCheckMobile();
  const logo = isMobile ? logomark : fullLogo;
  const rightTextToShow = rightText ? t(rightText) : "";

  const redirectionUrl =
    language === "es" ? `${process.env.REACT_APP_AWS_CMS}/es` : `${process.env.REACT_APP_AWS_CMS}`;

  return (
    <>
      <FlexLayout justify="space-between">
        <ButtonBase
          onClick={() =>
            process.env.REACT_APP_AWS_CMS ? (window.location.href = redirectionUrl) : null
          }
        >
          <img src={logo} alt="fullLogo" />
        </ButtonBase>
        <FlexLayout align="center" justify="flex-end">
          {rightTextToShow && (
            <Button variant="text" onClick={onClick}>
              <LarsseitTypography padding="0 15px 0 0">{t(rightTextToShow)}</LarsseitTypography>
            </Button>
          )}
          <ChooseLanguage showLabel />
        </FlexLayout>
      </FlexLayout>
    </>
  );
};

export default BcoHeader;
