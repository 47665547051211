/* eslint-disable react/react-in-jsx-scope */
import { StyledEngineProvider, ThemeProvider } from "@mui/material";

import i18n from "config/i18n";
import { router } from "config/routes";
import { CustomTheme } from "config/theme";

import { I18nextProvider } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={CustomTheme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </StyledEngineProvider>
      </I18nextProvider>
    </>
  );
}

export default App;
