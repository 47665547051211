import React, { useCallback, useEffect, useRef, useState } from "react";
import { useScript } from "shared/hooks/useScript";
import { ecommerceTracking } from "shared/utils/analytics";

export interface VideoData {
  title?: string;
  image?: string;
  file: string;
  caption?: string | null;
}

export type JWPlayer = jwplayer.JWPlayer & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPlugin: any;
  addButton: (
    icon: string,
    label: string,
    handler: () => void,
    id: string,
    btnClass: string,
  ) => jwplayer.JWPlayer;
};

interface IVideoPlayerProps {
  playlist?: VideoData[] | string;
  singleVideo?: VideoData;
}

export const VIDEO_ID_PREFIX = "jw-player-id-";

const VideoPlayer: React.FC<IVideoPlayerProps> = ({ singleVideo }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [durationVideo, setDurationVideo] = useState<number>(10000);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [eventFired, setEventFired] = useState(false);
  const [eventCompletedVideoFired, setEventCompletedVideoFired] = useState(false);

  const jwScript = useScript(process.env.REACT_APP_JW_PLAYER_SCRIPT ?? "", true);
  const googleTag = useScript("https://imasdk.googleapis.com/js/sdkloader/ima3.js", true);
  const jwRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const infoAboutPlayer = () => {
    const player = typeof jwplayer !== "undefined" ? (jwplayer() as JWPlayer) : null;
    const playerInfo = player?.getPlaylistItem();
    const mediaId = playerInfo?.file.split("/")[4];
    return {
      player,
      playerInfo,
      mediaId,
    };
  };

  const triggerTackingEvent = useCallback((playedPercentage = 1): void => {
    const { player, playerInfo, mediaId } = infoAboutPlayer();

    if (player && playerInfo) {
      ecommerceTracking(playedPercentage, {
        id: mediaId,
        title: playerInfo.title,
        variant: playerInfo.tags?.replace(",", "|").toLowerCase() || undefined,
        duration: playerInfo.duration || player.getDuration(),
        eventCategory: "video",
      });
    }

    setEventFired(true);
  }, []);
  const triggerTackingEventCompleted = useCallback((playedPercentage = 1): void => {
    const { player, playerInfo, mediaId } = infoAboutPlayer();

    if (player && playerInfo) {
      ecommerceTracking(playedPercentage, {
        id: mediaId,
        title: playerInfo.title,
        variant: playerInfo.tags?.replace(",", "|").toLowerCase() || undefined,
        duration: playerInfo.duration || player.getDuration(),
        eventCategory: "video",
      });
    }
    setEventCompletedVideoFired(true);
  }, []);

  const loadJWPlayer = useCallback(async () => {
    const jwPlaylist: jwplayer.PlaylistItem[] = [
      {
        file: singleVideo?.file || "",
        image: singleVideo?.image || "",
        title: singleVideo?.title || "",
        sources: [],
      },
    ];
    if (window?.AdBridg && window?.AdBridg?.exists) {
      //window?.AdBridg = window?.AdBridg || { cmd: [] }
      window?.AdBridg.cmd.push({
        action: "bco.preroll",
        current_setup: {
          playlist: jwPlaylist,
        },
        preroll_cb(new_setup_obj: jwplayer.SetupConfig) {
          jwplayer(jwRef.current).setup(new_setup_obj);
        },
      });
      setPlayerLoaded(true);
    } else {
      jwplayer(jwRef.current).setup({
        playlist: jwPlaylist,
      });
      setPlayerLoaded(true);
    }
  }, [singleVideo?.file]);

  useEffect(() => {
    if (jwScript === "ready" && googleTag) {
      loadJWPlayer();
    }
  }, [loadJWPlayer, googleTag, jwScript]);

  useEffect(() => {
    if (playerLoaded) {
      const play = document.querySelector("div .jw-icon-playback");

      if (!play) jwplayer().getState() === "paused" ? setIsPlaying(false) : setIsPlaying(true);

      play?.addEventListener("click", function (e) {
        e.stopPropagation();
        isPlaying ? setIsPlaying(false) : setIsPlaying(true);
      });

      let getTime: NodeJS.Timeout;
      if (isPlaying) {
        getTime = setInterval(() => {
          setCurrentTime(jwplayer().getPosition());
        }, 1000);
        return () => clearInterval(getTime);
      }
    }
  }, [playerLoaded, isPlaying]);

  useEffect(() => {
    if (isPlaying && currentTime >= 30 && !eventFired) {
      const timeVideo = jwplayer()?.getDuration() ?? 10;

      setDurationVideo(timeVideo ? timeVideo : 10000);
      triggerTackingEvent(1);
    }
  }, [currentTime, eventFired, isPlaying, triggerTackingEvent]);

  useEffect(() => {
    if (isPlaying && currentTime >= durationVideo && !eventCompletedVideoFired) {
      triggerTackingEventCompleted(100);
    }
  }, [
    eventCompletedVideoFired,
    currentTime,
    isPlaying,
    triggerTackingEventCompleted,
    durationVideo,
  ]);

  return (
    <>
      <div ref={jwRef}>Video Loading</div>
    </>
  );
};

export default VideoPlayer;
