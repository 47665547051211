import axios from "axios";

export interface IToken {
  confirmationCode?: string;
  locale: string;
  accessToken: string;
}

export const cognitoSignupConfirmation = async (payload: IToken, token: string) => {
  const { data } = await axios
    .post(`${process.env.REACT_APP_AWS_COGNITO_URL}/user-verification`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((error) => {
      throw new Error(`CONFIRM_SIGN_UP_EXCEPTION: ${error.message}`);
    });

  return data;
};

export const cognitoPasswordUpdate = async (
  username: string,
  newPassword: string,
  oldPassword: string,
  locale: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data }: any = await axios.post(
    `${process.env.REACT_APP_AWS_COGNITO_URL}/password-update`,
    {
      username,
      newPassword,
      oldPassword,
      locale,
    },
  );

  return { data };
};

export const cognitoPasswordRecovery = async (
  username: string,
  token: string,
  password: string,
  locale: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data }: any = await axios.patch(
    `${process.env.REACT_APP_AWS_COGNITO_URL}/user/${username}/password-recovery`,
    {
      token,
      password,
      locale,
    },
  );

  return { data };
};
