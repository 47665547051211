export const sourceLang = (search: string) => {
  const langs = ["en", "es"];
  const isolateLangs = (langs: string) => {
    const position = search.indexOf(langs);
    const languageFlagThreshold = 2;
    if (position !== -1) {
      return search.substring(position, position + languageFlagThreshold);
    }
  };
  const foundLang = langs.map(isolateLangs).filter(Boolean).toString();
  const foundDifferentLanguages = 3;
  return foundLang.length >= foundDifferentLanguages ? "en" : foundLang;
};
