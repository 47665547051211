interface IlanguagesToChoose {
  language: string;
  label: string;
}

export const languagesToChoose: IlanguagesToChoose[] = [
  {
    language: "es",
    label: "Español",
  },
  {
    language: "en",
    label: "English",
  },
];
