/* eslint-disable @typescript-eslint/no-explicit-any */
import { boolean, object, ref, string } from "yup";

const signupSchema = object({
  name: string(),
  lastName: string(),
  username: string()
    .matches(/^\S*$/, "error.username")
    .max(30, "error.username")
    .required("error.username")
    .test("email-format", "error.username", (value: any) => {
      return /^[a-zA-Z0-9._]{6,20}$/.test(value);
    }),
  emailAddress: string().required("error.required").email("error.email"),
  reEmailAddress: string()
    .required("error.required")
    .oneOf([ref("emailAddress")], "error.reEmailAddress"),
  password: string()
    .required("error.required")
    .min(8, "error.password")
    .max(20, "error.password")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*?])/, "error.password"),
  rePassword: string()
    .required("error.required")
    .oneOf([ref("password")], "error.rePassword"),
  getEmail: boolean(),
  adult: boolean().required("error.required").oneOf([true], "error.required"),
  termsAndRules: boolean().required("error.required").oneOf([true], "error.required"),
  privacy: boolean().required("error.required").oneOf([true], "error.required"),
});

export default signupSchema;
