import * as React from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useLocation } from "react-router-dom";
//styles
import { LanguagesTooltip, ShowFlags, CurrentLanguage } from "./styles";

//data
import { languagesToChoose } from "./data";
import { FlexLayout } from "shared/globalStyles";

interface IProps {
  showLabel?: boolean;
}
interface IlanguagesToChoose {
  language: string;
  label: string;
}
type IlanguageObj = Record<string, IlanguagesToChoose>;

const ChooseLanguage = ({ showLabel = false }: IProps) => {
  const { i18n } = useTranslation("global");
  const { language } = i18n;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    location.search = `${value}`;
    navigate({
      search: location.search,
    });
  };

  const languageObj: IlanguageObj = languagesToChoose.reduce(
    (acc, item) => ({ ...acc, [item.language]: item }),
    {},
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const currentLanguage = languageObj[language]?.label;

  return (
    <LanguagesTooltip
      placement="bottom"
      disableFocusListener
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={
        <FlexLayout flexDirection="column">
          {languagesToChoose.map((item) => (
            <React.Fragment key={item.language}>
              <IconButton
                sx={{
                  borderRadius: 0,
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={() => changeLanguage(item.language)}
              >
                <CurrentLanguage>{item.label} </CurrentLanguage>
              </IconButton>
            </React.Fragment>
          ))}
        </FlexLayout>
      }
    >
      <ShowFlags size="large">
        {showLabel && <CurrentLanguage>{currentLanguage} </CurrentLanguage>}
      </ShowFlags>
    </LanguagesTooltip>
  );
};

export default ChooseLanguage;
