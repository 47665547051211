import axios from "axios";

export interface IAWSForgotPasswordResponse {
  $metadata: {
    httpStatusCode: number;
    requestId: string;
    attempts: number;
    totalRetryDelay: number;
  };
  CodeDeliveryDetails: {
    AttributeName: string;
    DeliveryMedium: string;
  };
}
export const cognitoForgotPassword = async (email: string, locale: string) => {
  const { $metadata }: IAWSForgotPasswordResponse = await axios.post(
    `${process.env.REACT_APP_AWS_COGNITO_URL}/forgot-password`,
    {
      email,
      locale,
    },
  );

  return { data: $metadata };
};
