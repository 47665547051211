import { getRandomId } from "shared/helpers/getRandomId";
import {
  DataLayerEventAction,
  IDataLayerEcommerceProduct,
  IEventPageInfo,
} from "shared/types/Analytics";
import { convertDateFormat, getGoogleAnalyticsDeviceId, getLocalTime } from "shared/utils/helpers";

export const ecommerceTracking = (pageReadPercentages: number, pageInfo: IEventPageInfo): void => {
  window.dataLayer = window.dataLayer || [];

  const pageProperties = window.dataLayer.filter(
    (item: Record<string, unknown>) => item.pageProperties,
  );

  const pageId = pageProperties[pageProperties.length - 1]?.pageProperties?.pageId || undefined;
  let name = pageInfo.title?.toLowerCase();
  if (!pageInfo.title) {
    const h1s = document.getElementsByTagName("h1");
    name = h1s[0]?.innerText?.toLowerCase() || undefined;
  }

  const pageReadEventAction: Record<number, DataLayerEventAction | undefined> = {
    0: "view",
    1: "start",
    25: "25% progress",
    50: "50% progress",
    75: "75% progress",
    100: "complete",
  };

  const step: Record<number, string> = {
    25: "1",
    50: "2",
    75: "3",
  };

  const pageReadEvent =
    pageReadPercentages < 100
      ? pageReadPercentages === 0
        ? "itemView"
        : pageReadPercentages === 1
          ? "addToCart"
          : "checkout"
      : "purchase";
  const eventValue = Math.round(
    (pageInfo.eventCategory === "article" ? pageInfo.wordsCount : pageInfo.duration) || 0,
  );
  const products: IDataLayerEcommerceProduct[] = [
    {
      name,
      id: pageInfo.id,
      price: eventValue,
      category: pageInfo.category || undefined,
      brand: pageInfo.eventCategory,
      variant: pageInfo.variant || undefined,
      quantity: pageReadPercentages === 0 ? undefined : 1,
    },
  ];

  window.dataLayer.push({
    event: pageReadEvent,
    eventProperties: {
      eventAction: pageReadEventAction[pageReadPercentages],
      nonInteraction: pageReadPercentages === 0,
      eventCategory: pageInfo.eventCategory,
      eventLabel: pageInfo.title
        ? `${pageInfo.title?.toLowerCase() || ""}|${pageInfo.id}`
        : `${name}|${pageInfo.id || pageId}`,
      eventValue,
    },
    ecommerce: {
      currencyCode: "USD", // This value must not change
      add: pageReadPercentages === 1 ? { products } : undefined,
      detail: pageReadPercentages === 0 ? { products } : undefined,
      purchase:
        pageReadPercentages === 100
          ? {
              actionField: {
                id: getRandomId(),
                revenue: eventValue,
              },
              products,
            }
          : undefined,
      checkout:
        pageReadPercentages < 100 && pageReadPercentages > 1
          ? {
              actionField: {
                step: step[pageReadPercentages],
              },
              products,
            }
          : undefined,
    },
  });
};

// Event: Account Registration Complete
export const registrationCompleteTracking = (
  userId: string,
  createdDate: string,
  isSubscriber: boolean,
): void => {
  const googleAnalyticsDeviceId = getGoogleAnalyticsDeviceId();

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "formComplete",
    eventProperties: {
      eventCategory: "account",
      eventAction: "registration complete",
      eventLabel: userId,
      eventValue: 1,
      nonInteraction: false,
    },
    userProperties: {
      timestamp: getLocalTime(),
      clientId: googleAnalyticsDeviceId,
      sessionId: "", // TODO: populate this prop with real data
      hasAccount: true,
      createdDate: convertDateFormat(createdDate),
      isSubscriber,
      userId,
    },
  });
};

export const registrationViewTracking = (): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "formView",
    eventProperties: {
      eventCategory: "account",
      eventAction: "registration view",
      eventLabel: "/login",
      eventValue: 1,
      nonInteraction: true,
    },
  });
};
