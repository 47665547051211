import styled from "@emotion/styled";
import { isMobile } from "shared/globalStyles";

export const ResetPasswordContainer = styled.div`
  height: 100%;
  padding: 50px 10% 10px 10%;
  ${isMobile(`
   padding:20px
   
  `)}
`;
