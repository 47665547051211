import { object, ref, string } from "yup";

const resetPasswordSchema = object({
  password: string()
    .required("error.required")
    .min(6, "error.passwordMessage")
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/, "error.password"),
  rePassword: string()
    .required("error.required")
    .oneOf([ref("password")], "error.rePassword"),
});

export default resetPasswordSchema;
