import { Box } from "@mui/material";
import { Theme } from "config/theme";
import { InfoIcon } from "pages/SignUp/styles";
import React from "react";
import { Control, FieldErrors, FieldValues, UseFormHandleSubmit } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomLink from "shared/components/CustomLink";
import TextButton from "shared/components/TextButton";
import CheckBoxControlled from "shared/components/form/CheckBoxControlled";
import TextInputControlled from "shared/components/form/TextInputControlled";
import { FlexLayout, GridColumnLayout, LarsseitTypography } from "shared/globalStyles";

import infoIcon from "../../images/info.svg";

interface IProps {
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  onSubmit: (value: FieldValues) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FieldErrors<any>;
  control: Control<{
    name?: string | undefined;
    getEmail?: boolean | undefined;
    lastName?: string | undefined;
    password: string;
    username: string;
    rePassword: string;
    emailAddress: string;
    reEmailAddress: string;
    adult: boolean;
    termsAndRules: NonNullable<boolean>;
    privacy: NonNullable<boolean>;
  }>;
  isLoading: boolean;
  responseError: string;
}

const SignUpForm: React.FC<IProps> = ({
  handleSubmit,
  onSubmit,
  errors,
  control,
  isLoading,
  responseError,
}) => {
  const { t } = useTranslation("global");
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexLayout flexDirection="column">
          <GridColumnLayout columnGap="2rem">
            <FlexLayout flexDirection="column">
              <TextInputControlled
                control={control}
                name={"name"}
                required={true}
                label={t("signUp.name")}
              />
              <LarsseitTypography>{t("signUp.nameAndLastNameMessage")}</LarsseitTypography>
            </FlexLayout>

            <TextInputControlled
              control={control}
              name={"lastName"}
              required={true}
              label={t("signUp.lastName")}
            />
          </GridColumnLayout>
          <Box pt="4vh" />
          <TextInputControlled
            control={control}
            name={"username"}
            required={true}
            error={errors?.username ? true : false}
            errorMessage={errors.username?.message}
            label={t("login.username")}
            style={{ textTransform: "lowercase" }}
            errorToPlaceholder={t("error.username")}
          />
          <Box
            display={"flex"}
            flexDirection={"row"}
            padding={"24px"}
            bgcolor={Theme.colors.info}
            borderRadius="10px"
            margin="12px 0 0"
          >
            <InfoIcon src={infoIcon} alt="infoIcon" />
            <LarsseitTypography>{t("signUp.usernameMessage")}</LarsseitTypography>
          </Box>
          <TextInputControlled
            control={control}
            name={"emailAddress"}
            required={true}
            error={errors?.emailAddress ? true : false}
            errorMessage={errors?.emailAddress?.message?.toString()}
            label={t("signUp.emailAddress")}
          />
          <Box pt="2vh" />
          <TextInputControlled
            control={control}
            name={"reEmailAddress"}
            required={true}
            error={errors?.reEmailAddress ? true : false}
            errorMessage={errors?.reEmailAddress?.message?.toString()}
            label={t("signUp.reEmailAddress")}
          />
          <Box pt="2vh" />
          <CheckBoxControlled
            control={control}
            name={"getEmail"}
            required={false}
            error={errors?.getEmail ? true : false}
            label={t("signUp.getEmail")}
            isLabelActive
          />
          <Box pt="2vh" />
          <TextInputControlled
            control={control}
            name={"password"}
            required={true}
            type="password"
            error={errors?.password ? true : false}
            errorMessage={errors?.password?.message?.toString()}
            label={t("signUp.password")}
            errorToPlaceholder={t("error.password")}
          />
          <Box pt="4vh" />
          <TextInputControlled
            control={control}
            name={"rePassword"}
            type="password"
            required={true}
            error={errors?.rePassword ? true : false}
            errorMessage={errors?.rePassword?.message?.toString()}
            label={t("signUp.rePassword")}
          />
          <Box pt="4vh" />
          <CheckBoxControlled
            control={control}
            name={"adult"}
            required={true}
            error={errors?.adult ? true : false}
            label={t("signUp.adult")}
            isLabelActive
          />
          <CheckBoxControlled
            control={control}
            name={"termsAndRules"}
            required={true}
            error={errors?.termsAndRules ? true : false}
            label={
              <>
                <CustomLink
                  text={t("signUp.termsAndRules")}
                  linkText={t("signUp.termsAndRulesLink")}
                  href="https://www.breastcancer.org/policies/terms-of-use"
                />
              </>
            }
            isLabelActive
          />
          <CheckBoxControlled
            control={control}
            name={"privacy"}
            required={false}
            error={errors?.privacy ? true : false}
            label={
              <>
                <CustomLink
                  href="https://www.breastcancer.org/policies/privacy"
                  text={t("signUp.privacy")}
                  linkText={t("signUp.privacyLik")}
                />
              </>
            }
            isLabelActive
          />
          <Box pt="4vh" />
          <TextButton
            variant="contained"
            type="submit"
            fullWidth={false}
            isLoadingButton={isLoading}
            text={t("signUp.joinNow").toString()}
          />
          <Box pt="4vh" />
        </FlexLayout>
        {responseError && (
          <LarsseitTypography color={Theme.colors.error} fontsize="16px">
            {responseError}
          </LarsseitTypography>
        )}
      </form>
    </>
  );
};

export default SignUpForm;
